import { AUTH_TOKEN, EXPIRE_LOGIN, REFRESH_TOKEN, USER_ID } from "../../constants/local";
import { saveItemInStorage } from "../../helpers/storage";
import {
  loginRequest,
  loginRequestFailed,
  loginRequestSucceeded,
} from "../action-creators/login";
import { apiLogin } from "../../api/login";
import { handleError } from "./error";

export const requestLogin = (payload, keepLogged) => async dispatch => {
  dispatch(loginRequest());
  try {
    const json = await apiLogin(payload);
    dispatch(loginRequestSucceeded(json));
    console.log(json)
    saveItemInStorage(AUTH_TOKEN, json.token, keepLogged);
    saveItemInStorage(USER_ID, json?.name, keepLogged);
    return await Promise.resolve(json);
  } catch (response) {
    return handleError(dispatch, loginRequestFailed, response);
  }
};
