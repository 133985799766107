import {
  LOGIN_REQUEST,
  LOGIN_REQUEST_FAILED,
  LOGIN_REQUEST_SUCCEEDED,
} from "../constants/login";

export const loginRequest = () => ({
  type: LOGIN_REQUEST
});

export const loginRequestSucceeded = (json) => ({
  type: LOGIN_REQUEST_SUCCEEDED,
  data: json
});

export const loginRequestFailed = (json) => ({
  type: LOGIN_REQUEST_FAILED,
  error: json
});
