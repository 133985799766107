import {
  CATEGORY_PRODUCTS,
  CATEGORY_PRODUCTS_FAILED,
  CATEGORY_PRODUCTS_SUCCEEDED,
  SHORT_PRODUCTS,
  SHORT_PRODUCTS_FAILED,
  SHORT_PRODUCTS_SUCCEEDED
} from "../constants/product";

export const product = (state = {
  data: {},
  isFetching: false,
  categoryProductsData: {},
  error: {}
}, action) => {
  let newState = { ...state };

  switch (action.type) {
    case SHORT_PRODUCTS:
      newState.isFetching = true;
      return newState;

    case CATEGORY_PRODUCTS:
      newState.isFetching = true;
      return newState;

    case SHORT_PRODUCTS_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case CATEGORY_PRODUCTS_SUCCEEDED:
      newState.isFetching = false;
      newState.categoryProductsData = action.data;
      newState.error = {};
      return newState;

    case CATEGORY_PRODUCTS_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.categoryProductsData = {};
      return newState;

    case SHORT_PRODUCTS_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = {};
      return newState;

    default:
      return newState;
  }
}