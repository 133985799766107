import React from 'react'
import './RightComponent.scss'
import carShop from '../../../assets/images/product/shopping-cart.svg'
import infoIcon from '../../../assets/images/product/info.svg'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useCart } from '../../../hooks/useCart';


const RightComponent = () => {
  const navigate = useNavigate();
  const category = useSelector(state => state.category)
  const product = useSelector(state => state.product)
  const { cart, addProduct } = useCart();

  function handleAddProduct(id) {
    addProduct(id, 1)
  }

  function shuffle(array) {
    for (let i = array?.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  return (
    <div className="RightComponent">
      <h1 className="product-title">CATEGORIAS</h1>
      <div className="widget_content">
        <ul className="list">
          {category?.data?.content?.map((item, index) => {
            return (
              <li key={index}>
                <Link to={`/categoria/${item.id}`} title={item.name}>{item.name}
                </Link>
              </li>)
          })}
        </ul>
      </div>

      <div className="widget__best-sellers">
        <h3 className='product-title'>Mais vendidos</h3>
        <div className="container-products-sellers">
          {product?.data?.content?.slice(0, 3).map((item, index) => {
            const isInCart = cart.find(cartItem => cartItem.id === item.id);
            return (
              <div key={index} className="product-seller">
                <div className="container-top-product">
                  <div className="product-image">
                    <img src={item.icon} alt={item.name} />
                  </div>

                  <a href={`/produtos/${item.id}`} className="product_title">
                    {item.name}
                  </a>
                </div>
                <div className="infos-product">
                  <span>R$ {item.price}</span>
                  <div>
                    {isInCart ? <button className='active'><img src={carShop} alt="Carrinho de compras" /></button> :
                      <button onClick={() => item?.id && handleAddProduct(item)}><img src={carShop} alt="Carrinho de compras" /></button>
                    }
                    <button
                      onClick={() => navigate(`/produtos/${item.id}`)}
                    ><img src={infoIcon} alt="Botão de informação" /></button>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default RightComponent
