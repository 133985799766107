import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Navbar from './common/Navbar/Navbar';
import Home from './pages/Home/Home';
import ProdutcDetails from './pages/ProdutcDetails/ProdutcDetails';
import Footer from './common/Footer/Footer';
import CollectionsList from './pages/CollectionsList/CollectionsList';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { CartProvider } from '../hooks/useCart';
import { useEffect } from 'react';
import { listCategorys } from '../redux/actions/category';
import { listShortProducts } from '../redux/actions/product';
import Checkout from './pages/Checkout/Checkout';
import Account from './pages/Account/Account';

function App() {
  const dispatch = useDispatch();
  const category = useSelector(state => state.category, shallowEqual)
  const product = useSelector(state => state.product, shallowEqual)

  useEffect(() => {
    if (Object.keys(category.data).length === 0 && !category.isFetching) {
      dispatch(listCategorys())
    }

    if (Object.keys(product.data).length === 0 && !product.isFetching) {
      dispatch(listShortProducts())
    }
  }, [])

  return (
    <div className="App">
      <BrowserRouter>
        <CartProvider>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/produtos/:produtoID" element={<ProdutcDetails />} />
            <Route path="/categoria/:id" element={<CollectionsList />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/conta" element={<Account />} />
          </Routes>
          <Footer />
        </CartProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;