import { apiCategoryProducts, apiGetShortProducts } from "../../api/product";
import {
  categoryProducts,
  categoryProductsFailed,
  categoryProductsSucceeded,
  shortProducts,
  shortProductsFailed,
  shortProductsSucceeded
} from "../action-creators/product";

export const listShortProducts = filters => async dispatch => {
  dispatch(shortProducts());
  try {
    const json = await apiGetShortProducts(filters);
    dispatch(shortProductsSucceeded(json));
    return await Promise.resolve(json);
  } catch (error) {
    dispatch(shortProductsFailed(error));
    return await Promise.reject(error);
  }
};

export const listProductsCategory = (id, filters) => async dispatch => {
  dispatch(categoryProducts());
  try {
    const json = await apiCategoryProducts(id, filters);
    dispatch(categoryProductsSucceeded(json));
    return await Promise.resolve(json);
  } catch (error) {
    dispatch(categoryProductsFailed(error));
    return await Promise.reject(error);
  }
};
