import React from 'react'
import './ProductCollections.scss'
import carShop from '../../../assets/images/product/shopping-cart.svg'
import infoIcon from '../../../assets/images/product/info.svg'
import { useNavigate } from 'react-router-dom'
import { useCart } from '../../../hooks/useCart'

const ProductCollections = ({item, cart}) => {
  const navigate = useNavigate();
  const { addProduct } = useCart();

  function handleAddProduct(id) {
    addProduct(id, 1)
  }
  return (
    <div className="ProductCollections">
      <div className="product-image-wrap">
        <a href={`/produtos/${item.id}`} title={item.name}>
          <img src={item.icon} alt={item.name} />
        </a>
      </div>

      <a href={`/produtos/${item.id}`} className="product_title">
        {item.name}
      </a>

      <div className="infos-product">
        <span>R$ {item.price}</span>
        <div>
          <button className={cart ? "active" : ""} onClick={() => !cart && item?.id && handleAddProduct(item)} ><img src={carShop} alt="Botão de informação" /></button>
          <button onClick={() => navigate(`/produtos/${item.id}`)} ><img src={infoIcon} alt="Botão de informação" /></button>
        </div>
      </div>
    </div>
  )
}

export default ProductCollections
