import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { combineReducers, createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import App from './components/App';
import { Provider } from "react-redux";
import reportWebVitals from './reportWebVitals';
import { category } from './redux/reducers/category';
import { product } from './redux/reducers/product';
import { login } from './redux/reducers/login';
import { profile } from './redux/reducers/profile';

const reducers = combineReducers({ category, product, login, profile });

const middleware = [thunk];

if (process.env.NODE_ENV !== "production") {
  middleware.push(createLogger());
}

const store = createStore(reducers, applyMiddleware(...middleware));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <App />
    </Provider>
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();