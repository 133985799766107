import { GET_PROFILE, GET_PROFILE_FAILED, GET_PROFILE_SUCCEEDED } from "../constants/profile";

export const getProfile = () => ({
  type: GET_PROFILE
});

export const getProfileSucceeded = (data) => ({
  type: GET_PROFILE_SUCCEEDED,
  data: data
});

export const getProfileFailed = (error) => ({
  type: GET_PROFILE_FAILED,
  error: error
});
