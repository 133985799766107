import React, { useState } from 'react'
import './Login.scss'
import { useDispatch } from 'react-redux';
import { requestLogin } from '../../../../redux/actions/login'

const Login = ({login}) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'senha') {
      setPassword(value);
    }
  }

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   dispatch(requestLogin({ email, password, shop: {id:"screen"} })).then((json) => {
  //     // navigate("/")
  //     console.log(json)
  //   }).catch(() => {
  //     // setFetching(false);
  //   })
  // }



  return (
    <div className='Login'>
      <div className='container-login' >
        <div className='container-form'>
          <label>
            Email:
          </label>
          <input
            type="email"
            name="email"
            value={email}
            onChange={handleInputChange}
          />
        </div>
        <div className='container-form'>
          <label>
            Senha:
          </label>
          <input
            type="password"
            name="senha"
            value={password}
            onChange={handleInputChange}
          />
        </div>
        <button onClick={() => alert("esqueceu")} className="forgot-password">Esqueceu a senha?</button>
        <div className='container-submit'><button className='submit-login' onClick={(e) => login(email, password)} type="submit">Entrar</button> <p>ou <button className="forgot-password">Retornar para Loja</button></p></div>
      </div>
    </div>
  )
}

export default Login
