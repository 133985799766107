import React from 'react'
import Slider from '../../common/Slider/Slider'
import './Home.scss';
import Showcase from '../../common/Showcase/Showcase';
import NewProducts from '../NewProducts/NewProducts';

const Home = () => {

  const slides = [
    {
      src: "//tm-shopify002-computers.myshopify.com/cdn/shop/t/2/assets/slide_1_image.jpg?v=149209294951587560311386263228",
      alt: 'Samsung',
      title: 'Samsung',
      description: 'Lorem ipsum dolor sit amet conse ctetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamc',
    },
    {
      src: "//tm-shopify002-computers.myshopify.com/cdn/shop/t/2/assets/slide_3_image.jpg?v=109051299982977408211386263280",
      alt: 'Toshiba',
      title: 'Toshiba',
      description: 'Lorem ipsum dolor sit amet conse ctetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamc',
    },
    {
      src: "//tm-shopify002-computers.myshopify.com/cdn/shop/t/2/assets/slide_2_image.jpg?v=63931277460684563101386263280",
      alt: 'Lenovo',
      title: 'Lenovo',
      description: 'Lorem ipsum dolor sit amet conse ctetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamc',
    },
    // Adicione mais slides conforme necessário
  ];

  return (
    <div className='Home'>
      <div className="container">
        <Slider slides={slides} />
        <Showcase />
        <NewProducts />
      </div>
    </div>
  )
}

export default Home
