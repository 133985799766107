import { webAPI, buildHeaders, getResponseOrThrow } from "./common";

export const apiGateways = async () => {
  try {
    const response = await fetch(`${webAPI}/gateways`, {
      method: "GET",
      headers: buildHeaders()
    });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch (error) {
    return await Promise.reject(error);
  }
};
