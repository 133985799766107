import { enterAPI, buildHeaders, getResponseOrThrow } from "./common";

export const apiRegister = async (payload) => {
  try {
    const response = await fetch(`${enterAPI}/register`, {
      method: "POST",
      headers: buildHeaders(),
      body: JSON.stringify(payload)
    });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch (error) {
    return await Promise.reject(error);
  }
};