import {
  CATEGORY_PRODUCTS,
  CATEGORY_PRODUCTS_FAILED,
  CATEGORY_PRODUCTS_SUCCEEDED,
  SHORT_PRODUCTS,
  SHORT_PRODUCTS_FAILED,
  SHORT_PRODUCTS_SUCCEEDED
} from "../constants/product";

export const shortProducts = () => ({
  type: SHORT_PRODUCTS
});

export const shortProductsSucceeded = (data) => ({
  type: SHORT_PRODUCTS_SUCCEEDED,
  data: data
});

export const shortProductsFailed = (error) => ({
  type: SHORT_PRODUCTS_FAILED,
  error: error
});

export const categoryProducts = () => ({
  type: CATEGORY_PRODUCTS
});

export const categoryProductsSucceeded = (data) => ({
  type: CATEGORY_PRODUCTS_SUCCEEDED,
  data: data
});

export const categoryProductsFailed = (error) => ({
  type: CATEGORY_PRODUCTS_FAILED,
  error: error
});
