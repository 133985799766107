import React from 'react'
import './Footer.scss';

const Footer = () => {
  return (
    <div className='Footer'>
      <div className="header-footer">
        <div className="block">
          <h3>SOBRE NOSSA LOJA</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Molestiae, vitae, deserunt, eaque, similique aliquam ratione quis ex aperiam fugit voluptas ipsam placeat</p>
        </div>

        <div className="block">
          <h3>catálogo</h3>
          <ul className="list">
            <li className="firstItem"><a href="/collections/accessories" title="">Accessories</a></li>
            <li><a href="/collections/cpus" title="">CPUs</a></li>
            <li><a href="/collections/hard-drives" title="">Hard Drives</a></li>
            <li><a href="/collections/keyboards-mice" title="">Keyboards / Mice </a></li>
            <li className="lastItem"><a href="/collections/monitors" title="">Monitors </a></li>
          </ul>
        </div>

        <div className="block">
          <h3>links</h3>
          <ul className="list">
            <li className="firstItem"><a href="/" title="">Home</a></li>
            <li><a href="/pages/about-us" title="">About Us</a></li>
            <li><a href="/blogs/news" title="">Blog</a></li>
            <li><a href="/pages/documentation" title="">Documentation</a></li>
            <li className="lastItem"><a href="/pages/contacts" title="">Contacts</a></li>
          </ul>
        </div>

        <div className="block">
          <h3>contatos</h3>
          <p className="adr">8901 Marmora Road, Glasgow,   D04 89GR</p>
          <p className="tel">Tel: 1-800-234-5678</p>
        </div>
      </div>

      <footer>
        <div className="container copyright" role="contentinfo">
          © 2024  All Rights Reserved. Design by NGSHOP.
        </div>
      </footer>
    </div>
  )
}

export default Footer
