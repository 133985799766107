import {
  GET_CATEGORYS,
  GET_CATEGORYS_FAILED,
  GET_CATEGORYS_SUCCEEDED
} from "../constants/category";

export const category = (state = {
  data: {},
  isFetching: false,
  error: {}
}, action) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_CATEGORYS:
      newState.isFetching = true;
      return newState;

    case GET_CATEGORYS_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case GET_CATEGORYS_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = {};
      return newState;

    default:
      return newState;
  }
}