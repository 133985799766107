import React, { useEffect, useState } from 'react'
import './CollectionsList.scss';
import RightComponent from '../../common/RightComponent/RightComponent';
import { apiGetNewProdutcs } from '../../../api/getNewProdutcs';
import ProductCollections from '../../common/ProductCollections/ProductCollections';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useParams } from 'react-router-dom';
import { listProductsCategory } from '../../../redux/actions/product';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../../../hooks/useCart';

const CollectionsList = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const product = useSelector(state => state.product, shallowEqual)
  const dispatch = useDispatch();
  const { cart } = useCart();


  useEffect(() => {
    dispatch(listProductsCategory(id)).then((json) => { json.content.length < 1 && navigate('/') })
  }, []);

  return (
    <div className='CollectionsList'>
      <div className="container-collections">
        <div className="breadcrumbs">
          <ul className="breadcrumb">
            <li className="firstItem">
              <a href="/" className="homepage-link" title="Back to the frontpage">Home</a>
              <span className="divider">/</span>
            </li>
            <span className="page-title">Catálogo</span>
          </ul>
        </div>

        <div className="collections-info">
          <div className="left-collections">
            <h1 className="product-title ">Produtos</h1>

            <div className="products">
              {product?.categoryProductsData?.content?.map((item, index) => {
                const isInCart = cart.find(cartItem => cartItem.id === item.id);
                if (isInCart) {
                  return <ProductCollections cart item={item} key={item.id} />
                } else {
                  return <ProductCollections item={item} key={item.id} />
                }
              })}
            </div>
          </div>
          <RightComponent />
        </div>
      </div>
    </div>
  )
}

export default CollectionsList
