import { enterAPI, buildHeaders, getResponseOrThrow } from "./common";

export const apiLogin = async (payload) => {
  try {
    const response = await fetch(`${enterAPI}/login`, {
      method: "POST",
      headers: buildHeaders(),
      body: JSON.stringify(payload)
    });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch (error) {
    return await Promise.reject(error);
  }
};

export const apiLoginRefresh = refreshToken =>
  fetch(`${enterAPI}/person/token/refresh`, {
    method: "POST",
    headers: buildHeaders(refreshToken)
  })
    .then(getResponseOrThrow)
    .then(json => {
      return Promise.resolve(json);
    })
    .catch(() => Promise.reject());