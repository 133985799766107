import { buildHeaders } from "./common";

export const apiGetDetailsProduct = async (id) => {
  try {
    const response = await fetch(`https://nsapi.ngsuit.com/shop/exposed/screen/product/${id}`, {
      method: "GET",
      headers: buildHeaders(),
    });

    if (!response.ok) {
      throw new Error(`Request failed with status: ${response.status}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};