import React, { useEffect, useState } from 'react'
import './ProdutcDetails.scss'
import { Link, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { apiGetDetailsProduct } from '../../../api/getDetailsProduct';
import arrowRight from '../../../assets/images/arrow-right.svg'
import infoIcon from '../../../assets/images/product/info.svg'
import RightComponent from '../../common/RightComponent/RightComponent';
import { ReactComponent as Decrement } from "../../../assets/images/product/decrement.svg";
import { ReactComponent as Increment } from "../../../assets/images/product/increment.svg";
import { useCart } from '../../../hooks/useCart';

const ProdutcDetails = () => {
  const params = useParams();
  const navigate = useNavigate();
  const category = useSelector(state => state.category)
  const product = useSelector(state => state.product)
  const [detailProduct, setDetailProduct] = useState([])
  const [images, setImages] = useState([])
  const { cart, addProduct, updateProductAmount, removeProduct } = useCart();
  const [amountProduct, setAmountProduct] = useState(1)

  const getProduct = async (id) => {
    try {
      const data = await apiGetDetailsProduct(id);
      setDetailProduct(data);
      setImages([data.icon])
    } catch (error) {
      console.log("deu ruim")
    }
  };

  function handleAddProduct(id) {
    addProduct(id, amountProduct)
  }

  useEffect(() => {
    if (params.produtoID) {
      getProduct(params.produtoID);
    }
  }, [params]);

  useEffect(() => {
    let existsInCard = cart.find(product => product.id === detailProduct?.id)
    existsInCard ? setAmountProduct(existsInCard.amount) : setAmountProduct(1);
  }, [cart, detailProduct?.id, updateProductAmount, removeProduct]);


  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const nextSlide = () => {
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentImageIndex((currentImageIndex - 1 + images.length) % images.length);
  };

  const changeSlide = (index) => {
    setCurrentImageIndex(index);
  };

  return (
    <div className='ProdutcDetails'>
      <div className="container-product-details">
        <div className="breadcrumbs">
          <ul className="breadcrumb">
            <li className="firstItem">
              <a href="/" className="homepage-link" title="Back to the frontpage">Home</a>
              <span className="divider">/</span>
            </li>
            <li>
              <Link to={`/categoria/${encodeURIComponent(category?.data?.content?.find(category => category.id === detailProduct?.category_id)?.id)}`} title="">{category?.data?.content?.find(category => category.id === detailProduct?.category_id)?.name}</Link> <span className="divider">/</span></li>
            <span className="page-title">{detailProduct.name}</span>
          </ul>
        </div>
        <div className="containers-product">
          <div className="center-product">
            <h1 className="product-title ">{detailProduct.name}</h1>
            <div className="details">
              <div className="carousel-container">
                <div className="carousel">
                  <img src={images[currentImageIndex]} alt="slide" />
                  <div className="container-buttons">
                    <button onClick={prevSlide}><img src={arrowRight} alt="Seta Esquerda" /></button>
                    <button onClick={nextSlide}><img src={arrowRight} alt="Seta Direita" /></button>
                  </div>
                </div>
                <div className="thumbnails">
                  {Array.isArray(images) && images.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`thumbnail-${index}`}
                      className={currentImageIndex === index ? "active" : ""}
                      onClick={() => changeSlide(index)}
                    />
                  ))}
                </div>
              </div>

              <div className="product-description">
                <p className="price">R${detailProduct.price}</p>
                <div className="purchase">
                  {/* <label for="quantity">Qty: </label><input min="1" type="number" id="quantity" name="quantity" value="1" className="input-small form-control">
                  </input>
                  <input className="btn btn-primary" type="submit" name="add" id="add-to-cart" value="Add to Cart">
                  </input> */}
                  <div className='contain-product'>
                    <button
                      disabled={false}
                      onClick={() => {
                        setAmountProduct(function (prevCount) {
                          if (prevCount > 1) {
                            return (prevCount -= 1);
                          } else {
                            return (prevCount = 1);
                          }
                        });
                      }}
                    >
                      <Decrement style={{ cursor: 'pointer' }} />
                    </button>
                    <p>{amountProduct}</p>
                    <button
                      disabled={false}
                      onClick={() => {
                        setAmountProduct(amountProduct + 1)
                      }}
                      data-testid="decrement-product"
                    >
                      <Increment style={{ cursor: 'pointer' }} />
                    </button>
                  </div>
                  <button onClick={() => detailProduct?.id && handleAddProduct(detailProduct)} className='add-cart'>Adicionar ao carrinho</button>
                </div>
                <div className="product_details">
                  <div className="product_type">Tipo: <a href={`/categoria/${encodeURIComponent(category?.data?.content?.find(category => category.id === detailProduct?.category_id)?.id)}`} title={detailProduct.name}>{category?.data?.content?.find(category => category.id === detailProduct?.category_id)?.name}</a></div>
                </div>
                <div className="product_description">
                  <h3>Description:</h3>
                  <p dangerouslySetInnerHTML={{ __html: detailProduct.description }} />
                </div>
              </div>
            </div>

            <div className="other-products">
              <h3 className='product-title'>OUTROS PRODUTOS</h3>

              <div className="container-other-products">
                {product.data.content?.slice(0, 3).map((item, index) => {
                  return (
                    <div key={index} className="product-other">
                      <div className="product-image-wrap">
                        <a href={`/produtos/${item.id}`} title={item.name}>
                          <img src={item.icon} alt={item.name} />
                        </a>
                      </div>
                      <a href={`/produtos/${item.id}`} className="product_title">
                        {item.name}
                      </a>
                      <div className="infos-product">
                        <span>R$ {item.price}</span>
                        <div>
                          <button onClick={() => navigate(`/produtos/${item.id}`)} ><img src={infoIcon} alt="Botão de informação" /></button>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>

          <RightComponent />
        </div>
      </div>
    </div>
  )
}

export default ProdutcDetails
