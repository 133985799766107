import React from 'react'
import "./Navbar.scss";
import { Link, useNavigate } from 'react-router-dom';
import userIcon from '../../../assets/images/navbar/user-icon.svg'
import cartIcon from '../../../assets/images/navbar/shopping-cart-icon.svg'
import arrowIcon from '../../../assets/images/navbar/arrow-circle-right.svg'
import { useCart } from '../../../hooks/useCart';

const Navbar = () => {
  const navigate = useNavigate();
  const { cart } = useCart();
  const cartSize = Object.keys(cart).length;

  return (
    <header className='Navbar'>
      <div className="container-header">
        <div className='header'>
          <h1 className="site-title">
            <Link to="/">DL Pay</Link>
          </h1>
          <div className="container-buttons">
            <button onClick={() => navigate('/conta')} className='user-button'><img src={userIcon} alt="Usuário" /></button>
            <button onClick={() => navigate('/checkout')} className='cart-button'>
              <div>
                <img src={cartIcon} alt="Carrinho" /> <p><strong>Carrinho: </strong> {cartSize >= 1 ? cartSize + " items" : cartSize}</p>
              </div>
              <img src={arrowIcon} alt='Flecha para direita' />
            </button>
          </div>
        </div>
      </div>

      <div id='navigation' className="navigation">
        <nav role='navigation'>
          <button onClick={() => navigate(`/catalogo`)}>
            Catálogo
          </button>
          <button>
            Sobre nós
          </button>
          <button>
            Contato
          </button>
        </nav>
      </div>
    </header>
  )
}

export default Navbar
