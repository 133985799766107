import React from 'react'
import './Showcase.scss'
import arrowRight from '../../../assets/images/showcase/arrow-circle-o-right.svg';

const Showcase = () => {
  return (
    <div className='Showcase'>
      <div role='button' className="banner">
        <img src="https://tm-shopify002-computers.myshopify.com/cdn/shop/t/2/assets/banner_1_img.png?v=46979679494353137861386337877" alt="Tablet" />
        <h3>Tablets</h3>
        <p>Lorem ipsum dolor sit amet conse ctetur adipisicing</p>
        <button><img src={arrowRight} alt="Flecha para direita" /></button>
      </div>
      <div role='button' className="banner">
        <img src="https://tm-shopify002-computers.myshopify.com/cdn/shop/t/2/assets/banner_1_img.png?v=46979679494353137861386337877" alt="Tablet" />
        <h3>Tablets</h3>
        <p>Lorem ipsum dolor sit amet conse ctetur adipisicing</p>
        <button><img src={arrowRight} alt="Flecha para direita" /></button>
      </div>
      <div role='button' className="banner">
        <img src="https://tm-shopify002-computers.myshopify.com/cdn/shop/t/2/assets/banner_1_img.png?v=46979679494353137861386337877" alt="Tablet" />
        <h3>Tablets</h3>
        <p>Lorem ipsum dolor sit amet conse ctetur adipisicing</p>
        <button><img src={arrowRight} alt="Flecha para direita" /></button>
      </div>
      <div role='button' className="banner">
        <img src="https://tm-shopify002-computers.myshopify.com/cdn/shop/t/2/assets/banner_1_img.png?v=46979679494353137861386337877" alt="Tablet" />
        <h3>Tablets</h3>
        <p>Lorem ipsum dolor sit amet conse ctetur adipisicing</p>
        <button><img src={arrowRight} alt="Flecha para direita" /></button>
      </div>
    </div>
  )
}

export default Showcase
