import { GET_PROFILE, GET_PROFILE_FAILED, GET_PROFILE_SUCCEEDED } from "../constants/profile";


export const profile = (state = {
  data: {},
  isFetching: false,
  error: {}
}, action) => {
  let newState = { ...state };

  switch (action.type) {
    case GET_PROFILE:
      newState.isFetching = true;
      return newState;

    case GET_PROFILE_SUCCEEDED:
      newState.isFetching = false;
      newState.data = action.data;
      newState.error = {};
      return newState;

    case GET_PROFILE_FAILED:
      newState.isFetching = false;
      newState.error = action.error;
      newState.data = {};
      return newState;

    default:
      return newState;
  }
}