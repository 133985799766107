import React, { useEffect, useState } from 'react'
import './NewProducts.scss';
import Product from '../../common/Product/Product';
import { apiGetNewProdutcs } from '../../../api/getNewProdutcs';
import { useSelector } from 'react-redux';
import { useCart } from '../../../hooks/useCart';

const NewProducts = () => {
  const product = useSelector(state => state.product)
  const { cart } = useCart();

  return (
    <div className='NewProducts'>
      <h3 className="page-title">
        Novos produtos
      </h3>
      <div className="container-products">
        {product?.data?.content?.map((item) => {
          const isInCart = cart.find(cartItem => cartItem.id === item.id);
          if (isInCart) {
            return <Product data={item} cart key={item.id} />
          } else {
            return <Product data={item} key={item.id} />
          }
        })}
      </div>
    </div>
  )
}

export default NewProducts
