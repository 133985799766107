import React, { useState } from 'react'
import './Product.scss'
import carShop from '../../../assets/images/product/shopping-cart.svg'
import infoIcon from '../../../assets/images/product/info.svg'
import { useNavigate } from 'react-router-dom'
import { useCart } from '../../../hooks/useCart';

const Product = ({ data, cart }) => {
  const navigate = useNavigate()
  const { addProduct } = useCart();
  const [amountProduct] = useState(1)
  // const [actived, setActived] = useState(false)

  function handleAddProduct(id) {
    addProduct(id, amountProduct)
  }
  return (
    <div className='Product'>
      <div className="product-image">
        <img src={data.icon} alt={`Imagem do produto ${data.name}`} />
      </div>
      <p className="description-product">{data.name}</p>
      <div className="infos-product">
        <span>R$ {data.price}</span>
        <div>
          <button className={cart ? "active" : ""} onClick={() => !cart && data?.id && handleAddProduct(data)}><img src={carShop} alt="Carrinho de compras" /></button>
          <button onClick={() => navigate(`/produtos/${data.id}`)}><img src={infoIcon} alt="Botão de informação" /></button>
        </div>
      </div>
    </div>
  )
}

export default Product
