export const buildHeaders = (authorization = "", ignoreContentType) => {
  const headers = {
    "Accept": "application/json",
  };

  if(!ignoreContentType) {
    headers["Content-Type"] = "application/json";
  }

  const auth = authorization;

  if (auth) {
    headers["Authorization"] = `Bearer ${auth}`
  }

  return headers;
}

const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getResponseOrThrow = async response => {
  let responseText = await response.text();
  let parsedResponse = isJsonString(responseText) ? JSON.parse(responseText) : responseText;

  if (response.ok) {
    return parsedResponse;
  } else {
    throw parsedResponse;
  }
};

export const urlFilters = (url, filters) => {
  if (filters === undefined) {
    return url;
  }

  const filtersKeys = Object.keys(filters);

  filtersKeys.map(key => {
    const keyValue = filters[key];
    if (keyValue !== '') {
      let symbol = url.includes('?') ? '&' : '?';
      if (Array.isArray(keyValue)) {
        for(let value of keyValue) {
          symbol = url.includes('?') ? '&' : '?';
          url += `${symbol}${key}=${value}`
        }
      }else {
        url += `${symbol}${key}=${keyValue}`
      }
    }
    return key
  });

  return url;
}

export const webAPI = process.env.REACT_APP_API;
export const enterAPI = process.env.REACT_APP_API_LOGIN;