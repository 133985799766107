import { apiGetCategorys } from "../../api/category";
import { getCategorys, getCategorysFailed, getCategorysSucceeded } from "../action-creators/category";

export const listCategorys = filters => async dispatch => {
  dispatch(getCategorys());
  try {
    const json = await apiGetCategorys(filters);
    dispatch(getCategorysSucceeded(json));
    return await Promise.resolve(json);
  } catch (error) {
    dispatch(getCategorysFailed(error));
    return await Promise.reject(error);
  }
};
