import { getResponseOrThrow, webAPI, urlFilters } from "./common";

export const apiGetShortProducts = filters =>
  fetch(urlFilters(`${webAPI}/product`, filters), {
    method: "GET"
  }).then(getResponseOrThrow);

export const apiCategoryProducts = (id, filters) =>
  fetch(urlFilters(`${webAPI}/category/${id}/product`, filters), {
    method: "GET"
  }).then(getResponseOrThrow);
