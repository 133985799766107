import React, { useEffect, useState } from 'react'
import './Account.scss'
import RightComponent from '../../common/RightComponent/RightComponent'
import Login from './Login/Login'
import Register from './Register/Register'
import { useDispatch, useSelector } from 'react-redux';
import { apiProfile } from '../../../api/profile'
import { getItemFromStorage, removeItemFromStorage } from '../../../helpers/storage'
import { AUTH_TOKEN } from '../../../constants/local'
import { requestLogin } from '../../../redux/actions/login'
import { infoProfile } from '../../../redux/actions/profile'

const Account = () => {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.profile)
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    if (getItemFromStorage(AUTH_TOKEN)) {
      dispatch(infoProfile());
    }
  }, []);

  const logoutAccount = () => {
    removeItemFromStorage("AUTH_TOKEN")
    window.location.href = '/conta'
  }

  const handleSubmitLogin = (email, password) => {
    dispatch(requestLogin({ email, password, shop: { id: "screen" } })).then((json) => {
      dispatch(infoProfile());
    }).catch(() => {
      // setFetching(false);
    })
  }

  const renderAccount = () => {
    if (profile?.data?.name) {
      return (
        <>
          <h1 className="account-title ">Detalhes da conta e pedidos</h1>
          <div className="container-account">
            <div className="name">
              <p>{profile?.data.name}</p>
              <button onClick={() => logoutAccount()} >Sair</button>
            </div>
            <p className='email'>{profile?.data?.email}</p>
            
          </div>
        </>
      )
    } else {
      return (
        <>
          <h1 className="account-title ">login</h1>
          <Login login={handleSubmitLogin} />
          <h1 className="account-title ">Criação de conta</h1>
          <Register />
        </>
      )
    }
  }

  return (
    <div className='Account'>
      <div className="account-container">
        <div className="breadcrumbs">
          <ul className="breadcrumb">
            <li className="firstItem">
              <a href="/" className="homepage-link" title="Back to the frontpage">Home</a>
              <span className="divider">/</span>
            </li>
            <span className="page-title">Conta</span>
          </ul>
        </div>

        <div className="checkout-account">
          <div className="left-account">
            {renderAccount()}
          </div>
          <RightComponent />
        </div>
      </div>
    </div>
  )
}

export default Account
