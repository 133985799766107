import React from 'react'
import RightComponent from '../../common/RightComponent/RightComponent'
import { ReactComponent as Decrement } from "../../../assets/images/product/decrement.svg";
import { ReactComponent as Increment } from "../../../assets/images/product/increment.svg";
import { ReactComponent as Exit } from "../../../assets/images/product/exit.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Checkout.scss';
import { useCart } from '../../../hooks/useCart';
import { formatPrice } from '../../../utils/format';
import { Link } from 'react-router-dom';
import { apiGateways } from '../../../api/gateways';
import { useDispatch, useSelector } from 'react-redux';
import { infoProfile } from '../../../redux/actions/profile';
import { apiPurchase } from '../../../api/purchase';


const Checkout = () => {
  const dispatch = useDispatch()
  const { cart, removeProduct, updateProductAmount } = useCart();

  function handleProductIncrement(product) {
    const cartItemIncrement = {
      productId: product.id,
      amount: product.amount + 1
    }
    updateProductAmount(cartItemIncrement)
  }

  function handleProductDecrement(product) {
    const cartItemIncrement = {
      productId: product.id,
      amount: product.amount - 1
    }
    updateProductAmount(cartItemIncrement)
  }

  function handleRemoveProduct(productId) {
    removeProduct(productId)
  }

  const total =
    formatPrice(
      cart.reduce((sumTotal, product) => {
        return sumTotal += product.amount * product.price
      }, 0)
    )

  const handlePurchase = () => {

    const postPurchase = async (payload) => {
      try {
        const purchase = await apiPurchase(payload);
        toast.success('Você será redirecionado para página de pagamento')

        setTimeout(() => {
          window.location.href = purchase.paymentLink;
        }, 2000);
      } catch (error) {
        toast.error('Houve um erro inesperado para finalizar a compra')
      }
    };


    dispatch(infoProfile()).then(() => {
      const registerUser = async () => {
        try {
          const gateways = await apiGateways();
          const productPurchaseInfoList = cart.map(product => {
            return {
              arguments: product.arguments,
              product: { id: product.id },
              productAmount: product.amount
            };
          });

          const payload = {
            productPurchaseInfoList,
            transaction: {
              paymentGateway: gateways[0]
            }
          }

          postPurchase(payload)
        } catch (error) {
          console.error("Deu ruim")
        }
      };

      registerUser()
    }).catch(() => toast.error('Você precisa estar logado para finalizar a compra!'))
  }

  return (
    <div className='Checkout'>
      <ToastContainer
        position="top-right"
        pauseOnFocusLoss
        autoClose={4000}
        theme='colored'
        draggable
      />
      <div className="container-checkout">
        <div className="breadcrumbs">
          <ul className="breadcrumb">
            <li className="firstItem">
              <a href="/" className="homepage-link" title="Back to the frontpage">Home</a>
              <span className="divider">/</span>
            </li>
            <span className="page-title">Seu checkout de compra</span>
          </ul>
        </div>

        <div className="checkout-info">
          <div className="left-checkout">
            <h1 className="product-title ">Checkout</h1>

            <div className="products">
              {cart.map((item, index) => {
                return (
                  <div key={index} className="product">
                    <div className="container-image">
                      <img src={item.icon} alt={item.name} />
                    </div>
                    <div className="infos-product">
                      <div className="name-product">
                        <h2 class="item-title">
                          <Link to={`/produtos/${item.id}`}>
                            {item.name}
                          </Link>
                          <Exit onClick={() => handleRemoveProduct(item.id)} className='exit' />
                        </h2>
                        <p className="item-vendor" />
                      </div>
                      <div className="price-product">
                        <div className="price-item">
                          <span>{formatPrice(item?.price)}</span>
                        </div>

                        <div className='contain-product'>
                          <button
                            // disabled={product.amount <= 1}
                            onClick={() => handleProductDecrement(item)}
                          >
                            <Decrement style={{ cursor: 'pointer' }} />
                          </button>
                          <p>{item.amount}</p>
                          <button
                            data-testid="decrement-product"
                            // disabled={product.amount <= 1}
                            onClick={() => handleProductIncrement(item)}
                          >
                            <Increment style={{ cursor: 'pointer' }} />
                          </button>
                        </div>

                        <div className="price-item">
                          <span>{formatPrice(item.price * item.amount)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
              <div className="subtotal">
                <div className="info-total">
                  <p>Subtotal</p>
                  <span>{total}</span>
                </div>
                <div className="finish-total">
                  <Link to="/">Continuar comprando</Link>
                  ou
                  <button onClick={() => handlePurchase()}>Finalizar compra</button>
                </div>
              </div>
            </div>
          </div>

          <RightComponent />
        </div>

      </div>
    </div>
  )
}

export default Checkout
