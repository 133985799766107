import { AUTH_TOKEN } from "../constants/local";
import { getItemFromStorage } from "../helpers/storage";
import { enterAPI, buildHeaders, getResponseOrThrow } from "./common";

export const apiProfile = async () => {
  const token = getItemFromStorage(AUTH_TOKEN)
  try {
    const response = await fetch(`${enterAPI}/profile`, {
      method: "GET",
      headers: buildHeaders(token)
    });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch (error) {
    return await Promise.reject(error);
  }
};
