import React, { useState, useEffect } from 'react';
import './Slider.scss'; 
import arrowRight from '../../../assets/images/arrow-right.svg'

const Slider = ({ slides }) => {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [autoplay] = useState(true);
  const [titleBackgroundColors, setTitleBackgroundColors] = useState([]);
  const [descriptionBackgroundColors, setDescriptionBackgroundColors] = useState([]);

  useEffect(() => {
    let intervalId;
    if (autoplay) {
      intervalId = setInterval(() => {
        nextSlide();
      }, 6000); 
    }
    return () => clearInterval(intervalId);
  }, [currentSlideIndex, autoplay]);

  const nextSlide = () => {
    setCurrentSlideIndex((currentSlideIndex + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlideIndex((currentSlideIndex - 1 + slides.length) % slides.length);
  };

  const randomColor = () => {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  };

  const randomDescriptionColor = () => {
    return '#' + Math.floor(Math.random() * 16237215).toString(16);
  };


  useEffect(() => {
    const colors = slides.map(() => randomColor());
    setTitleBackgroundColors(colors);

    const colorsDescription = slides.map(() => randomDescriptionColor());
    setDescriptionBackgroundColors(colorsDescription);
  }, [currentSlideIndex, slides]);

  return (
    <div className="slider">
      <div className="slides-container">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={index === currentSlideIndex ? 'slide active' : 'slide'}
          >
            <img src={slide.src} alt={slide.alt} />
            <div className="slide-content">
              <h2 style={{ backgroundColor: titleBackgroundColors[index] }}>{slide.title}</h2>
              <p style={{ backgroundColor: descriptionBackgroundColors[index] }}>{slide.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="container-info">
        <button className="prev" onClick={prevSlide}>
          <img src={arrowRight} alt="" />
        </button>
        <button className="next" onClick={nextSlide}>
          <img src={arrowRight} alt="" />
        </button>
      </div>
    </div>
  );
};

export default Slider;
