import { apiProfile } from "../../api/profile";
import { getProfile, getProfileFailed, getProfileSucceeded } from "../action-creators/profile";

export const infoProfile = () => async dispatch => {
  dispatch(getProfile());
  try {
    const json = await apiProfile();
    dispatch(getProfileSucceeded(json));
    return await Promise.resolve(json);
  } catch (error) {
    dispatch(getProfileFailed(error));
    return await Promise.reject(error);
  }
};
