import { GET_CATEGORYS, GET_CATEGORYS_FAILED, GET_CATEGORYS_SUCCEEDED} from "../constants/category";

export const getCategorys = () => ({
    type: GET_CATEGORYS
});

export const getCategorysSucceeded = (data) => ({
  type: GET_CATEGORYS_SUCCEEDED,
  data: data
});

export const getCategorysFailed = (error) => ({
  type: GET_CATEGORYS_FAILED,
  error: error
});
