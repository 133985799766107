import { AUTH_TOKEN } from "../constants/local";
import { getItemFromStorage } from "../helpers/storage";
import { buildHeaders, getResponseOrThrow } from "./common";

export const apiPurchase = async (payload) => {
  const token = getItemFromStorage(AUTH_TOKEN)
  try {
    const response = await fetch(`https://nsapi.ngsuit.com/purchase`, {
      method: "POST",
      headers: buildHeaders(token),
      body: JSON.stringify(payload)
    });
    const json = await getResponseOrThrow(response);
    return await Promise.resolve(json);
  } catch (error) {
    return await Promise.reject(error);
  }
};