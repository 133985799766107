import React, { useState } from 'react'
import './Register.scss'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiRegister } from '../../../../api/register';


const Register = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const emptyForms = () => {
    setConfirmPassword('')
    setPassword('')
    setEmail('')
    setName('')
  }

  const registerUser = async () => {
    try {
      const payload = {
        name,
        email,
        password,
        marketing: false,
        shop: {
          id: "screen"
        }
      };
      await apiRegister(payload);
      emptyForms()
      toast.success('Registro efetuado com sucesso, agora faça o login!');
    } catch (error) {
      toast.error('Erro ao registrar usuário');
    }
  };

  const handleSubmit = () => {
    if(password === confirmPassword) {
      if (name && email && password && confirmPassword) {
        registerUser()
      } else {
        toast.error('Preencha todos os campos para cadastro!');
      }
    } else {
      toast.error('As senhas estão diferentes!');
    }
  }

  return (
    <div className='Register'>
      <ToastContainer
        position="top-right"
        pauseOnFocusLoss
        autoClose={4000}
        theme='colored'
        draggable
      />
      <div className='container-register' onSubmit={handleSubmit}>
        <div className='container-form'>
          <label>
            Nome:
          </label>
          <input
            type="text"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className='container-form'>
          <label>
            Email:
          </label>
          <input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className='container-form'>
          <label>
            Senha:
          </label>
          <input
            type={showPassword ? 'text' : 'password'}
            name="senha"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className='container-form'>
          <label>
            Confirmação de senha:
          </label>
          <input
            type={showPassword ? 'text' : 'password'}
            name="senha"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <button className='view-password' onClick={toggleShowPassword}>
            {showPassword ? 'Ocultar Senha' : 'Mostrar Senha'}
          </button>
        </div>
        <div className='container-submit'><button className='submit-login' onClick={() => handleSubmit()}>Registrar</button> <p>ou <button className="forgot-password">Retornar para Loja</button></p></div>
      </div>
    </div>
  )
}

export default Register
